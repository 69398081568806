import React from 'react'
import styled from 'styled-components'
import { Billboard } from '@renderbus/common/components'
import { Media, spacing, typography, mbSize } from '@renderbus/common/theme'
import { graphql, Link, useStaticQuery } from 'gatsby'

export const QingyunContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: white;
  ${Media.lessThan(Media.small)} {
    padding-top: 150px;
    align-items: flex-start;
    text-align: center;
  }
`

export const QingyunContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto 50px;
  width: 1200px;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
  }
`

export const QingyunBannerTitleContainer = styled.div`
  h5 {
    color: white;
    font-size: 18px;
    font-weight: 400;
    margin: 0 0 30px;
  }
  ${Media.lessThan(Media.small)} {
    h5 {
      font-size: ${typography.textThin};
      margin: 0 0 ${spacing.base};
    }
  }
`

export const QingyunTitle = styled.div`
  font-size: 62px;
  line-height: 1;
  text-shadow: 3px 3px 1px rgba(34, 34, 34, 0.5);
  font-weight: bold;
  span.green {
    color: #13cb75;
  }

  ${Media.lessThan(Media.small)} {
    font-size: 27px;
  }
`

export const QingyunTitle2 = styled.div`
  font-size: 36px;
  line-height: 1;
  margin-top: 16px;
  text-shadow: 2px 1px 2px rgba(34, 34, 34, 0.5);

  span {
    font-size: 48px;
    font-weight: bold;
    color: #13cb75;
  }

  ${Media.lessThan(Media.small)} {
    font-size: 18px;

    span {
      font-size: 24px;
    }
  }
`

export const QingyunBannerTitle = styled.img`
  margin-bottom: ${spacing.large};
  max-width: 90%;
  ${Media.lessThan(Media.small)} {
    margin-bottom: ${spacing.base};
    &:first-of-type {
      max-width: 100%;
    }
  }
`

export const QingyunBannerButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 152px;
  height: 38px;
  border-radius: 19px;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 0 2.5em;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background: radial-gradient(circle at 100%, #3fbc2b 0%, #40c570 100%);
  transition: all 0.3s;
  :hover {
    box-shadow: 0px 10px 30px 10px rgba(64, 194, 93, 0.3);
  }
  ${Media.lessThan(Media.small)} {
    margin: 20px auto 20px;
    width: ${mbSize(240)};
    height: ${mbSize(60)};
    line-height: ${mbSize(60)};
    font-size: ${mbSize(24)};
    padding: 0;
  }
`

export default React.memo(function QingyunBanner() {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { regex: "/banner/cpu-qingyun/cpu-qingyun-bg.png/" }) {
        ...fluidImage
      }
      bannerMb: file(relativePath: { regex: "/banner/cpu-qingyun/cpu-qingyun-bg-mb.png/" }) {
        ...fluidImage
      }
    }
  `)
  return (
    <QingyunContainer>
      <QingyunContent>
        <QingyunBannerTitleContainer>
          <QingyunTitle>
            <span className='green'>超值渲染! </span>
            <span>青云平台火热上线</span>
          </QingyunTitle>
          <QingyunTitle2>
            CPU渲染低至<span>0.03125</span>元/核时
          </QingyunTitle2>
          <QingyunBannerButton to='/go-cloud.html?ban'>了解详情</QingyunBannerButton>
        </QingyunBannerTitleContainer>
      </QingyunContent>
      <Billboard
        fluid={[
          data.banner.childImageSharp.fluid,
          { ...data.bannerMb.childImageSharp.fluid, media: `(max-width: 780px)` },
        ]}
      />
    </QingyunContainer>
  )
})
