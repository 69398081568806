import React from 'react'
import { Media, mbSize } from '@renderbus/common/theme'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { Billboard } from '@renderbus/common/components'
import TitleImg from '../../images/banner/animation-extreme-speed-banner/title.png'
import { isTest } from '@renderbus/common/service'
import Img from 'gatsby-image'
import { baseLink } from '@renderbus/common/service'

export const PictureBannerContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: ${p => (p.isBanner ? '100vh' : '1080px')};
  color: white;
  padding-top: 176px;
  font-family: 'Source Han Sans CN';
  ${Media.lessThan(Media.small)} {
    padding-top: ${mbSize(246)};
    height: ${p => (p.isBanner ? '100vh' : mbSize(1624))};
  }
`

export const BannerWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  img {
    width: 760px;
  }
  ${Media.lessThan(Media.small)} {
    img {
      width: ${mbSize(712)};
    }
  }
`
export const SmallIcon = styled(Img)`
  width: 30px;
  height: 26px;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(28)};
    height: ${mbSize(24)};
  }
`
export const Desc = styled.div`
  display: flex;
  font-size: 30px;
  color: #ffffff;
  line-height: 29px;
  /* text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.4); */
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
  letter-spacing: 1px;
  margin: 16px 0 40px;
  p {
    margin: 0;
  }
  span {
    display: flex;
    align-items: center;
    color: #ffecb2;
    font-weight: bold;
    ${SmallIcon} {
      margin: 0 8px;
    }
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(27)};
    line-height: unset;
    letter-spacing: ${mbSize(2)};
    margin: ${mbSize(16)} 0 ${mbSize(40)};
    span {
      ${SmallIcon} {
        margin: 0 ${mbSize(8)};
      }
    }
  }
`

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 40px;
  border-radius: 20px;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  background: radial-gradient(circle at 100%, #3fbc2b 0%, #40c570 100%);
  transition: all 0.3s;
  :hover {
    box-shadow: 0px 10px 30px 10px rgba(64, 194, 93, 0.3);
  }
  cursor: pointer;
  ${Media.lessThan(Media.small)} {
    font-weight: bold;
    width: ${mbSize(240)};
    height: ${mbSize(60)};
    line-height: ${mbSize(60)};
    font-size: ${mbSize(24)};
    /* margin-top: ${mbSize(40)}; */
  }
`

export default React.memo(function AnimationExtremeSpeedBanner({ isBanner = true }) {
  const data = useStaticQuery(graphql`
    query {
      banner: file(
        relativePath: { regex: "/banner/animation-extreme-speed-banner/banner_bg.png/" }
      ) {
        ...fluidImage
      }
      bannerMb: file(
        relativePath: { regex: "/banner/animation-extreme-speed-banner/banner_bg_mobile.png/" }
      ) {
        ...fluidImage
      }
      bannerMbPage: file(
        relativePath: { regex: "/banner/animation-extreme-speed-banner/banner_bg_mobile_page.png/" }
      ) {
        ...fluidImage
      }
      coupon: file(relativePath: { regex: "/banner/animation-extreme-speed-banner/coupon.png/" }) {
        ...fluidImage
      }
      diamond: file(
        relativePath: { regex: "/banner/animation-extreme-speed-banner/diamond.png/" }
      ) {
        ...fluidImage
      }
    }
  `)

  const handleClick = e => {
    e.nativeEvent.stopImmediatePropagation()
    if (typeof window !== 'undefined') {
      const bannerBaseLink = isTest()
        ? 'http://pre.renderbus.com:8081'
        : 'https://www.renderbus.com'
      window.location.href = isBanner
        ? `${bannerBaseLink}/ysdh-xrj-lqzn.html`
        : `${baseLink()}/sso/register`
    }
  }

  return (
    <PictureBannerContainer isBanner={isBanner}>
      <BannerWrap>
        <img src={TitleImg} alt='' />
        <Desc>
          注册福利
          <span>
            <SmallIcon fluid={data.coupon.childImageSharp.fluid} />
            100元无门槛渲染券 +
            <SmallIcon fluid={data.diamond.childImageSharp.fluid} />
            7天五折渲染权益
          </span>
        </Desc>
        <Button onClick={handleClick}>{isBanner ? '了解详情' : '领取优惠'}</Button>
      </BannerWrap>
      <Billboard
        fluid={[
          data.banner.childImageSharp.fluid,
          {
            ...(isBanner ? data.bannerMb : data.bannerMbPage).childImageSharp.fluid,
            media: `(max-width: 780px)`,
          },
        ]}
      />
    </PictureBannerContainer>
  )
})
