import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { Billboard } from '@renderbus/common/components'
import {
  ButtonImg,
  ButtonWrapper,
  Content,
  Duration,
  Mark,
  MidAutumnContainer,
  SubTitleImg,
  TitleImg,
} from './atoms'

const query = graphql`
  query {
    banner: file(relativePath: { regex: "/banner/mid-autumn-2024/bg.webp/" }) {
      ...fluidImage
    }
    bannerPage: file(relativePath: { regex: "/banner/mid-autumn-2024/bg_page.webp/" }) {
      ...fluidImage
    }
    bannerMb: file(relativePath: { regex: "/banner/mid-autumn-2024/bg_mobile.webp/" }) {
      ...fluidImage
    }
    bannerMbPage: file(relativePath: { regex: "/banner/mid-autumn-2024/bg_mobile_page.webp/" }) {
      ...fluidImage
    }
    title: file(relativePath: { regex: "/banner/mid-autumn-2024/title.webp/" }) {
      ...fluidImage
    }
    subtitle: file(relativePath: { regex: "/banner/mid-autumn-2024/subtitle.webp/" }) {
      ...fluidImage
    }
    btn: file(relativePath: { regex: "/banner/mid-autumn-2024/btn.png/" }) {
      ...fluidImage
    }
    btnHover: file(relativePath: { regex: "/banner/mid-autumn-2024/btn-hover.png/" }) {
      ...fluidImage
    }
  }
`

export function MidAutumnBanner2024({ inPage = false }) {
  const data = useStaticQuery(query)

  return (
    <MidAutumnContainer
      as={inPage ? undefined : Link}
      to={inPage ? '' : '/architectural-rendering-monthly-promotion.html?utm_source=index-banner'}
      inPage={inPage}
    >
      <Content>
        <Mark>效果图用户专享</Mark>
        <TitleImg
          fluid={[
            data.title.childImageSharp.fluid,
            { ...data.title.childImageSharp.fluid, media: `(max-width: 780px)` },
          ]}
        />
        <SubTitleImg
          fluid={[
            data.subtitle.childImageSharp.fluid,
            { ...data.subtitle.childImageSharp.fluid, media: `(max-width: 780px)` },
          ]}
        />
        <Duration>2024.9.13-2024.10.23</Duration>
        {!inPage && (
          <ButtonWrapper
            as={Link}
            to='/architectural-rendering-monthly-promotion.html?utm_source=index-banner'
          >
            <ButtonImg className='normal' fluid={[data.btn.childImageSharp.fluid]} />
            <ButtonImg className='hover' fluid={[data.btnHover.childImageSharp.fluid]} />
            <span>查看详情</span>
          </ButtonWrapper>
        )}
      </Content>
      <Billboard
        fluid={[
          (inPage ? data.bannerPage : data.banner).childImageSharp.fluid,
          {
            ...(inPage ? data.bannerMbPage : data.bannerMb).childImageSharp.fluid,
            media: `(max-width: 780px)`,
          },
        ]}
      />
    </MidAutumnContainer>
  )
}
