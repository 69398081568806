import React from 'react'
import styled from 'styled-components'
import { Billboard } from '@renderbus/common/components'
import { Media } from '@renderbus/common/theme'
import { useLocation } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'
import DeatilBg from '../../images/banner/year-end-recharge-2023-banner/year-end-recharge-bg.png'
import DeatilPrizeBg from '../../images/banner/year-end-recharge-2023-banner/prize.png'
import DeatilPrizeMbBg from '../../images/banner/year-end-recharge-2023-banner/prize-mb.png'
import DeatilPrizeBannerMbBg from '../../images/banner/year-end-recharge-2023-banner/prize-banner-mb.png'
import DeatilMbBg from '../../images/banner/year-end-recharge-2023-banner/year-end-recharge-bg-mobile.png'

import { PageViewIndex } from '@renderbus/common/constant/page-view'

const BannerContainer = styled.div`
  position: relative;
  height: ${props => (props.isBanner ? '100vh' : '1080px')};
  background: ${p =>
    !p.isBanner && `#080826 ${`url(${DeatilBg})`} 0px 0px / 100% 1080px no-repeat`};
  align-items: center;
  justify-content: center;
  display: flex;
  .gatsby-image-wrapper {
    margin-top: 60px;
  }
  @media screen and (max-width: 1500px) {
    height: ${props => (props.isBanner ? '100vh' : '940px')};
    background: ${p =>
      !p.isBanner && `#080826 ${`url(${DeatilBg})`} 0px 0px / 100% 940px no-repeat`};
  }
  ${Media.lessThan(Media.small)} {
    height: ${props => (props.isBanner ? '100vh' : '660px')};
    background: ${p => !p.isBanner && `#0D1A15 ${`url(${DeatilMbBg})`} 0px 0px / cover no-repeat`};
  }
`
const RechargeButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 48px;
  color: #ffffff;
  outline: none;
  border: none;
  font-size: 20px;
  background: linear-gradient(180deg, #ff9933 0%, #ff4c6a 100%);
  border-radius: 24px;
  font-weight: bold;
  :hover {
    background: linear-gradient(0deg, #ffe6b2 0%, #fff6e5 100%);
    box-shadow: 0px 0px 18px 2px rgba(15, 38, 31, 0.6);
    color: #14664b;
  }
  ${Media.lessThan(Media.small)} {
    border-radius: 18px;
    font-size: 14px;
    height: 34px;
    width: 120px;
    height: 30px;
    font-size: 15px;
  }
`

const DetailTypeRadio = styled.div`
  background: linear-gradient(0deg, #0b261d 0%, #174d3b 100%);
  box-shadow: 0px 6px 5px 0px rgba(15, 38, 31, 0.4);
  border-radius: 60px;
  margin: 0 auto;
  height: 100px;
  padding: 12px;
  display: flex;
  position: absolute;
  bottom: 200px;
  border: 1px solid rgba(255, 230, 178, 0.5);
  ${Media.lessThan(Media.small)} {
    padding-left: 0;
    height: 60px;
    padding: 5px;
    bottom: 160px;
  }
`
const DetailTypeRadioItem = styled.div`
  width: 480px;
  height: 76px;
  border-radius: 48px;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
  color: ${p => (p.isActive ? '#14664B' : '#EEEEEE')};
  line-height: 26px;
  background: ${p => p.isActive && 'linear-gradient(0deg, #FFE6B2 0%, #FFF6E5 100%)'};
  background-position: 100% 100%;
  box-shadow: ${p => p.isActive && '0px 0px 18px 2px rgba(15,38,31,0.4)'};
  transition: background-position 0.3s, color 0.3s, transform 0.3s ease;
  &:hover {
    background: ${p => !p.isActive && 'linear-gradient(0deg, #FFE6B2 0%, #FFF6E5 79.5166015625%)'};
    -webkit-background-clip: ${p => !p.isActive && 'text'};
    -webkit-text-fill-color: ${p => !p.isActive && 'transparent'};
  }
  ${Media.lessThan(Media.small)} {
    width: 160px;
    height: 48px;
    font-size: 15px;
    border-radius: 30px;
    line-height: 13px;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 236px;
  a:nth-child(1) {
    margin-right: 40px;
  }
  ${Media.lessThan(Media.small)} {
    bottom: 200px;
    a:nth-child(1) {
      margin-right: 20px;
    }
  }
`
const BannerPrizeContainer = styled.img`
  width: 100%;
  position: absolute;
  top: 0;
  max-width: 1920px;
  display: ${p => p.isBanner && 'none'};
  ${Media.lessThan(Media.small)} {
    display: block;
    top: ${p => p.isBanner && '20px'};
    content: ${p => `url(${p.isBanner ? p.mbBannerSrc : p.mbSrc})`};
  }
`
const YearEndRecharge2023Banner = React.memo(function({ isPictureType, handleChangeType }) {
  const location = useLocation()
  const isIndex = location.pathname === '/'
  const data = useStaticQuery(graphql`
    query {
      banner: file(
        relativePath: { eq: "banner/year-end-recharge-2023-banner/year-end-recharge-banner-bg.png" }
      ) {
        ...fluidImage
      }
      bannerMobile: file(
        relativePath: { eq: "banner/year-end-recharge-2023-banner/year-end-recharge-bg-mobile.png" }
      ) {
        ...fluidImage
      }
    }
  `)

  return (
    <BannerContainer isBanner={isIndex}>
      <BannerPrizeContainer
        src={DeatilPrizeBg}
        mbSrc={DeatilPrizeMbBg}
        mbBannerSrc={DeatilPrizeBannerMbBg}
        isBanner={isIndex}
      />
      {isIndex ? (
        <ButtonWrapper>
          <RechargeButton
            as='a'
            href='/ysdhnzhd.html'
            target='_blank'
            id='ysdhnzhd-ljxq'
            data-bury-click
            data-index={PageViewIndex.index}
          >
            动画专区
          </RechargeButton>
          <RechargeButton
            as='a'
            href='/ysdhnzhd.html?type=picture'
            target='_blank'
            id='xgt-monthly-promotion'
            data-bury-click
            data-index={PageViewIndex.index}
          >
            效果图专区
          </RechargeButton>
        </ButtonWrapper>
      ) : (
        <DetailTypeRadio>
          <DetailTypeRadioItem
            onClick={() => handleChangeType(false)}
            isActive={!isPictureType}
            isTurnRight={isPictureType}
          >
            动画十万豪礼相送
          </DetailTypeRadioItem>
          <DetailTypeRadioItem
            onClick={() => handleChangeType(true)}
            isActive={isPictureType}
            isTurnRight={!isPictureType}
          >
            效果图钜惠惊喜三连
          </DetailTypeRadioItem>
        </DetailTypeRadio>
      )}
      {isIndex && (
        <Billboard
          fluid={[
            data.bannerMobile.childImageSharp.fluid,
            {
              ...data.banner.childImageSharp.fluid,
              media: `(min-width: 780px)`,
            },
          ]}
        />
      )}
    </BannerContainer>
  )
})

export default YearEndRecharge2023Banner
